import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_FACTURACION_CAPITA

export default class NormaRepartoService {
  // Listar las normas de reparto
  getListNormaReparto () {
    return http.get(`${baseUrl}/norma-reparto/listar-norma-reparto`, {
      headers: {
        loading: true
      }
    })
  }
}
