import { defineStore } from 'pinia'

import FacturasCapitaValorService from '@/apps/pharmasan/facturacion/capita/services/facturasCapitaValor.service'
import ClientesService from '@/apps/pharmasan/facturacion/capita/services/clientes.service'
import NormaRepartoService from '@/apps/pharmasan/facturacion/capita/services/normaReparto.service'
import ZonasService from '@/apps/pharmasan/facturacion/capita/services/zonas.service'

const _FacturasCapitaValorService = new FacturasCapitaValorService()
const _ClientesService = new ClientesService()
const _NormaRepartoService = new NormaRepartoService()
const _ZonasService = new ZonasService()

export const useInfoFacturasCapitaStore = defineStore({
  id: 'infoFacturasCapita',
  state: () => ({
    _infoFacturaCapita: {
      U_PHR_ModContrato: '01',
      U_PHR_MotAutoriza: '04',
      U_PHR_Cn_Aut: '04',
      U_PHR_MesCapita: new Date().toLocaleString('es-CO', { month: 'long' }).toUpperCase(),
      U_PHR_YearCapita: new Date().toLocaleString('es-CO', { year: 'numeric' }),
      U_PHR_RegPac: null,
      U_PHR_RegimenF: null,
      U_PHR_UsuarCapita: null,
      U_PHR_NumCtoF: null,
      U_PHR_VlrUntF: null,
      U_PHR_EstadoFac: null,
      U_PHR_Desc1: null,
      U_PHR_Rips: 'S',
      Zonificacion: {
        U_PHR_CodMunicipio: null,
        U_PHR_MunicipioF: null,
        U_PHR_DptoAfiliacion: null,
        DptMunicipio: null
      },
      CardCode: null,
      DocDate: new Date().toISOString().split('T')[0],
      DocDueDate: new Date().toISOString().split('T')[0],
      TaxDate: new Date().toISOString().split('T')[0],
      Comments: null,
      DiscPrcnt: 0,
      DocumentLines: [{
        Index: 0,
        ItemCode: 'CAPITA001',
        WarehouseCode: 'BOD0001',
        CostingCode: null,
        Quantity: 1,
        Price: 0,
        PriceTotal: 0
      }]
    },
    _listModalidadContrato: [],
    _listMotivoAutorizacion: [],
    _listContratoAutorizacion: [],
    _listMeses: [
      { value: 'ENERO', label: 'ENERO' },
      { value: 'FEBRERO', label: 'FEBRERO' },
      { value: 'MARZO', label: 'MARZO' },
      { value: 'ABRIL', label: 'ABRIL' },
      { value: 'MAYO', label: 'MAYO' },
      { value: 'JUNIO', label: 'JUNIO' },
      { value: 'JULIO', label: 'JULIO' },
      { value: 'AGOSTO', label: 'AGOSTO' },
      { value: 'SEPTIEMBRE', label: 'SEPTIEMBRE' },
      { value: 'OCTUBRE', label: 'OCTUBRE' },
      { value: 'NOVIEMBRE', label: 'NOVIEMBRE' },
      { value: 'DICIEMBRE', label: 'DICIEMBRE' }
    ],
    _listYears: [],
    _listClientes: [],
    _listArticulos: [
      { value: 'CAPITA001', label: 'CAPITA001' },
      { value: 'CT_0002', label: 'CT_0002' }
    ],
    _listNormaReparto: [],
    _listZonas: [],
    _listRegimen: [],
    _listRegimenF: [],
    _listEstadosCartera: [],
    _listRips: [
      { value: 'S', label: 'SI' },
      { value: 'N', label: 'NO' }
    ],
    _lastNumFactura: 0,
    _toggleUdfFactura: true,
    _formSubmitted: false,
    _isValidForm: true,
    _messageErrors: []
  }),
  getters: {
    getInfoFacturaCapita: (state) => state._infoFacturaCapita,
    getToggleUdfFactura: (state) => state._toggleUdfFactura,
    getListModalidadContrato: (state) => state._listModalidadContrato,
    getListMotivoAutorizacion: (state) => state._listMotivoAutorizacion,
    getListContratoAutorizacion: (state) => state._listContratoAutorizacion,
    getListMeses: (state) => state._listMeses,
    getListYears: (state) => state._listYears,
    getListClientes: (state) => state._listClientes,
    getListArticulos: (state) => state._listArticulos,
    getListNormaReparto: (state) => state._listNormaReparto,
    getListZonas: (state) => state._listZonas,
    getListRegimen: (state) => state._listRegimen,
    getListRegimenF: (state) => state._listRegimenF,
    getListEstadosCartera: (state) => state._listEstadosCartera,
    getLastNumFactura: (state) => state._lastNumFactura
  },
  actions: {
    setInfoFacturaCapita (payload) {
      this._infoFacturaCapita = payload
    },
    setToggleUdfFactura (payload) {
      this._toggleUdfFactura = payload
    },
    setListStatus (payload) {
      this._listModalidadContrato = payload
    },
    setListMotivoAutorizacion (payload) {
      this._listMotivoAutorizacion = payload
    },
    setListContratoAutorizacion (payload) {
      this._listContratoAutorizacion = payload
    },
    setListClientes (payload) {
      this._listClientes = payload
    },
    setListArticulos (payload) {
      this._listArticulos = payload
    },
    setListNormaReparto (payload) {
      this._listNormaReparto = payload
    },
    setListZonas (payload) {
      this._listZonas = payload
    },
    setLastNumFactura (payload) {
      this._lastNumFactura = payload
    },
    setListRegimen (payload) {
      this._listRegimen = payload
    },
    setListRegimenF (payload) {
      this._listRegimenF = payload
    },
    setListEstadosCartera (payload) {
      this._listEstadosCartera = payload
    },
    _actionClearInfoFacturaCapita () {
      this.setInfoFacturaCapita({
        U_PHR_ModContrato: '01',
        U_PHR_MotAutoriza: '04',
        U_PHR_Cn_Aut: '04',
        U_PHR_MesCapita: new Date().toLocaleString('es-CO', { month: 'long' }).toUpperCase(),
        U_PHR_YearCapita: new Date().toLocaleString('es-CO', { year: 'numeric' }),
        U_PHR_RegPac: null,
        U_PHR_RegimenF: null,
        U_PHR_UsuarCapita: null,
        U_PHR_NumCtoF: null,
        U_PHR_VlrUntF: null,
        U_PHR_EstadoFac: null,
        U_PHR_Desc1: null,
        U_PHR_Rips: 'S',
        Zonificacion: {
          U_PHR_CodMunicipio: null,
          U_PHR_MunicipioF: null,
          U_PHR_DptoAfiliacion: null,
          DptMunicipio: null
        },
        CardCode: null,
        DocDate: new Date().toISOString().split('T')[0],
        DocDueDate: new Date().toISOString().split('T')[0],
        TaxDate: new Date().toISOString().split('T')[0],
        Comments: null,
        DiscountPercent: 0,
        DocumentLines: [{
          Index: 0,
          ItemCode: 'CAPITA001',
          WarehouseCode: 'BOD0001',
          CostingCode: null,
          Quantity: 1,
          Price: 0,
          PriceTotal: 0
        }]
      })
      this._formSubmitted = false
      this._isValidForm = true
      this._messageErrors = []
    },
    async _actionGetListModalidadContrato () {
      try {
        const { data } = await _FacturasCapitaValorService.getListModalidadContrato()
        this.setListStatus(data.result)
      } catch (error) {
        console.log('Error en _actionGetListModalidadContrato', error)
      }
    },
    async _actionGetListMotivoAutorizacion () {
      try {
        const { data } = await _FacturasCapitaValorService.getListMotivoAutorizacion()
        this.setListMotivoAutorizacion(data.result)
      } catch (error) {
        console.log('Error en _actionGetListMotivoAutorizacion', error)
      }
    },
    async _actionGetListContratoAutorizacion () {
      try {
        const { data } = await _FacturasCapitaValorService.getListContratoAutorizacion()
        this.setListContratoAutorizacion(data.result)
      } catch (error) {
        console.log('Error en _actionGetListContratoAutorizacion', error)
      }
    },
    async _actionGetListYears () {
      const currentYear = new Date().getFullYear()
      this._listYears = [
        { value: (currentYear - 1).toString(), label: (currentYear - 1).toString() },
        { value: currentYear.toString(), label: currentYear.toString() },
        { value: (currentYear + 1).toString(), label: (currentYear + 1).toString() }
      ]
    },
    async _actionGetListClientes () {
      try {
        const { data } = await _ClientesService.getListClientes()
        this.setListClientes(data.result)
      } catch (error) {
        console.log('Error en _actionGetListClientes', error)
      }
    },
    async _actionGetListNormaReparto () {
      try {
        const { data } = await _NormaRepartoService.getListNormaReparto()
        this.setListNormaReparto(data.result)
      } catch (error) {
        console.log('Error en _actionGetListNormaReparto', error)
      }
    },
    async _actionGetListZonas () {
      try {
        const { data } = await _ZonasService.getListZonas()
        this.setListZonas(data.result)
      } catch (error) {
        console.log('Error en _actionGetListZonas', error)
      }
    },
    async _actionGetLastNumFactura () {
      try {
        const { data } = await _FacturasCapitaValorService.getUltimoNumeroFactura()
        this.setLastNumFactura(data.result)
      } catch (error) {
        console.log('Error en _actionGetLastNumFactura', error)
      }
    },
    async _actionGetListRegimen () {
      try {
        const { data } = await _FacturasCapitaValorService.getListRegimen()
        this.setListRegimen(data.result)
      } catch (error) {
        console.log('Error en _actionGetListRegimen', error)
      }
    },
    async _actionGetListRegimenF () {
      try {
        const { data } = await _FacturasCapitaValorService.getListRegimenF()
        this.setListRegimenF(data.result)
      } catch (error) {
        console.log('Error en _actionGetListRegimenF', error)
      }
    },
    async _actionGetListEstadosCartera () {
      try {
        const { data } = await _FacturasCapitaValorService.getListEstadosCartera()
        this.setListEstadosCartera(data.result)
      } catch (error) {
        console.log('Error en _actionGetListEstadosCartera', error)
      }
    }
  }
})
