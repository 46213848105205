<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})
const router = useRouter()

//
const onCargaMasiva = (data) => {
  const { DocEntry, DocNum } = data

  router.push({
    name: 'pharmasan.ventas.facturacion.capita-valor.carga-ov-factura',
    params: {
      DocEntry,
      DocNum
    }
  })
}

</script>

<template>
  <div class="flex w-full justify-center items-center">
    <Button
      icon="pi pi-file-excel"
      severity="warning"
      size="small"
      @click="onCargaMasiva(props.data)"
      rounded
      outlined
      v-tippy="{ content: 'Carga Masiva' }"
    />
  </div>
</template>

<style scoped>

</style>
