import http from '@/libs/http'
import { useStore } from '@/store'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_FACTURACION_CAPITA

export default class FacturasCapitaValorService {
  // Listar las modalidades de contrato de la factura
  getListModalidadContrato () {
    return http.get(`${baseUrl}/facturas/listar-modalidad-contrato`, {
      headers: {
        loading: true
      }
    })
  }

  // Listar los motivos de autorización de la factura
  getListMotivoAutorizacion () {
    return http.get(`${baseUrl}/facturas/listar-motivo-autorizacion`, {
      headers: {
        loading: true
      }
    })
  }

  // Listar los contrato de autorización de la factura
  getListContratoAutorizacion () {
    return http.get(`${baseUrl}/facturas/listar-contrato-autorizacion`, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el ultimo número de factura
  getUltimoNumeroFactura () {
    return http.get(`${baseUrl}/facturas/ultimo-numero-factura`, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el listado de regimen
  getListRegimen () {
    return http.get(`${baseUrl}/facturas/listar-regimen`, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el listado de regimenF
  getListRegimenF () {
    return http.get(`${baseUrl}/facturas/listar-regimenf`, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el listado de estados de cartera
  getListEstadosCartera () {
    return http.get(`${baseUrl}/facturas/listar-estados-cartera`, {
      headers: {
        loading: true
      }
    })
  }

  // Obtener el listado de facturas capita con valor
  getListFacturasCapitaValor (params) {
    return http.get(`${baseUrl}/facturas/listar-facturas`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Obtener el detalle de la factura
  getDetalleFactura (params) {
    return http.get(`${baseUrl}/facturas/listar-detalle`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Guardar y enviar la factura
  saveSendFacturaCapitaValor (body) {
    return http.post(`${baseUrl}/facturas/crear`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Buscar una factura por DocNum
  searchFactura (DocNum) {
    return http.get(`${baseUrl}/facturas/buscar/${DocNum}`, {
      headers: {
        loading: true
      }
    })
  }

  // Descargar plantilla excel
  downloadTemplate (params) {
    const store = useStore()
    return new Promise((resolve) => {
      const url = `${baseUrl}/facturas/descargar-plantilla-excel`
      const token = store.getters['auth/getToken']
      const res = http.getUri({ url, params: { token, ...params } })
      resolve(res)
    })
  }

  // Validar la plantilla
  validateTemplate (data) {
    return http.post(`${baseUrl}/facturas/validar-plantilla`, data, {
      headers: {
        loading: true
      }
    })
  }

  // Guardar las ovs relacionadas de la factura
  saveOvsFactura (body) {
    return http.post(`${baseUrl}/facturas/guardar-ovs-factura`, body, {
      headers: {
        loading: true
      }
    })
  }
}
