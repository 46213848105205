<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import FacturasCapitaValorService from '@/apps/pharmasan/facturacion/capita/services/facturasCapitaValor.service'
import { useFiltersFacturasCapitaStore } from '@/apps/pharmasan/facturacion/capita/stores/filtersFacturasCapita.store'
import { useInfoFacturasCapitaStore } from '@/apps/pharmasan/facturacion/capita/stores/infoFacturasCapita.store'
import Icon from '@/components/Icon/index.vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import SidebarAdvancedFilters from '@/apps/pharmasan/facturacion/capita/views/list/components/sidebarAdvancedFilters.vue'
import ColumnTemplateDefault from '@/apps/pharmasan/facturacion/capita/views/list/table/components/columnTemplateDefault.vue'
import ColumnTemplateCurrency from '@/apps/pharmasan/facturacion/capita/views/list/table/components/columnTemplateCurrency.vue'
import ColumnTemplateCantOvs from '@/apps/pharmasan/facturacion/capita/views/list/table/components/columnTemplateCantOvs.vue'
import ColumnTemplateActionsEnd from '@/apps/pharmasan/facturacion/capita/views/list/table/components/columnTemplateActionsEnd.vue'

const infoFacturasCapitaStore = useInfoFacturasCapitaStore()
const filtersFacturasCapitaStore = useFiltersFacturasCapitaStore()
const {
  _filtersAdvanced,
  _filtersApply,
  _listFiltersFields,
  _listFiltersConditions,
  _buscarListadoFacturasCapita,
  _refrescarListadoFacturasCapita,
  _limpiarListadoFacturasCapita
} = storeToRefs(filtersFacturasCapitaStore)
const route = useRoute()
const router = useRouter()
const _FacturasCapitaValorService = ref(new FacturasCapitaValorService())
const listFacturasCapitaValor = ref([])
const limit = ref(20)
const offset = ref(0)
const orderField = ref('DocNum')
const sortOrder = ref(-1)
const filtersUrl = ref([])
const filtersApplyContent = ref('')
const listColumns = ref([])
const selectedColumns = ref([])
const detailFactura = ref([])
const detailColumnsFactura = ref([])
const expandedRows = ref([])
const refSidebarAdvancedFilters = ref(null)
const columnComponents = reactive({
  actions: ColumnTemplateActionsEnd,
  CantidadOvs: ColumnTemplateCantOvs
})

// Abrir el sidebar de filtros
const onSidebarAdvancedFilters = (data) => {
  refSidebarAdvancedFilters.value.openSidebarAdvancedFilters(data)
}

// Función que permite la actualización de parámetros de consulta de manera dinámica antes de realizar la navegación a una nueva URL.
const _routerPush = (obj = {}) => {
  const queryParams = route.query
  router.push({ query: { ...queryParams, ...obj } })
}

// Ordenar por campo
const orderQuery = ({ sortField, sortOrder }) => {
  _routerPush({ order: `${sortField},${sortOrder}` })
}

// Paginación
const onPage = (row) => {
  const queryParams = { ...route.query }
  limit.value = row.rows
  offset.value = row.first

  // _routerPush({ ...queryParams, limit: row.rows, offset: row.first })
  router.push({ query: { ...queryParams, limit: row.rows, offset: row.first } })
}

// Asignacion de los query params
const setQueryParams = () => {
  const queryParams = { ...route.query }
  limit.value = queryParams.limit ? parseInt(queryParams.limit) : 20
  offset.value = queryParams.offset ? parseInt(queryParams.offset) : 0
  queryParams.limit = queryParams.limit ? queryParams.limit : 20
  queryParams.offset = queryParams.offset ? queryParams.offset : 0
  queryParams.order = queryParams.order ? queryParams.order : 'DocNum,-1'

  // Split a la cadena para obtener el campo y el orden
  const splitOrder = queryParams.order.split(',')
  orderField.value = splitOrder[0]
  sortOrder.value = parseInt(splitOrder[1])

  return queryParams
}

// Función para obtener el listado de facturas capita valor
const fetchListFacturasCapitaValor = async () => {
  try {
    const { data } = await _FacturasCapitaValorService.value.getListFacturasCapitaValor(setQueryParams())
    listFacturasCapitaValor.value = data
    listColumns.value = data.columns
    // Agregar la columna de acciones al final
    listColumns.value.push({ field: 'actions', header: 'Acciones' })
  } catch (error) {
    console.error('Error al obtener el listado de facturas capita valor', error)
  }
}

// Funcion para exportar a excel
const exportarExcel = async () => {
  console.log('Exportar a excel')
}

// Funcion para limpiar los filtros
const resetClear = () => {
  // Limpiar _filtersAdvanced
  _filtersAdvanced.value = [{ nameField: null, condition: null, showName: null, dataType: null, value: null }]
  _filtersApply.value = [{ nameField: null, condition: null, showName: null, dataType: null, value: null }]
}

// Funcion para retornar el router query de la URL
const getRouteQuery = async () => {
  // Si el objeto no viene vació
  if (Object.keys(route.query).length !== 0) {
    delete route.query.limit
    delete route.query.offset
    delete route.query.order

    // Decodificar los filtros avanzados
    if (route.query.filters) {
      _filtersAdvanced.value = JSON.parse(decodeURIComponent(route.query.filters))
      _filtersApply.value = JSON.parse(decodeURIComponent(route.query.filters))
    }
  } else {
    resetClear()
  }
}

// Funcion para obtener el detalle de la factura por DocEntry
const getDetailFactura = async (DocEntry) => {
  try {
    const { data } = await _FacturasCapitaValorService.value.getDetalleFactura({ DocEntry })
    detailFactura.value = data.result ?? []
    detailColumnsFactura.value = data.columns ?? []
  } catch (error) {
    console.error('Error al obtener el detalle de la factura', error)
  }
}

// Expandir y contraer filas
const onRowExpand = async ({ data }) => {
  console.log('onRowExpand', data)
  await getDetailFactura(data.DocEntry)
  expandedRows.value = [data]
}
const onRowCollapse = (event) => {
  console.log('onRowCollapse', event)
  expandedRows.value = []
}

// Función para retornar la clase CSS de la fila
const rowClass = (data) => {
  // Verifica si la fila actual está en expandedRows
  if (expandedRows.value[0] && data.DocEntry === expandedRows.value[0].DocEntry) {
    return 'expanded-row'
  }

  return ''
}

// Funcion para limpiar los filtros
const resetClearFilter = () => {
  console.log('Limpiar Filtros...')
  filtersFacturasCapitaStore.setLimpiarListadoFacturasCapita(true)
}

// Funcion para recargar la vista
const reload = () => {
  console.log('Recargar...')
  filtersFacturasCapitaStore.setRefrescarListadoFacturasCapita(true)
}

// Función para mostrar los filtros aplicados
const getFiltersApplyV2 = () => {
  let htmlFilters = ''
  if (_filtersApply.value.length > 0 && _filtersApply.value[0].nameField) {
    if (!_filtersApply.value[0].conditions && !_filtersApply.value[0].choiceList) {
      // Agregar choiceList y conditions a los filtros dependiendo del campo, sobre _listFiltersFieldsAll y _listFiltersConditions
      _filtersApply.value.forEach(obj => {
        const field = _listFiltersFields.value.find((field) => {
          return field.nameField === obj.nameField
        })
        obj.choiceList = field.choiceList
          ? field.choiceList.map(item => {
            return {
              value: item.id ?? item.value,
              label: item.name ?? item.label
            }
          })
          : []
        obj.conditions = _listFiltersConditions.value.find(item => item.dataType === obj.dataType).conditions
      })
    }
    const result = _filtersApply.value.map(obj => {
      const conditionName = obj.conditions.find(cond => cond.value === obj.condition)?.name
      // Validar si choiceList es un array vació, sino values es igual a obj.value
      const valuesFilters = obj.choiceList && obj.choiceList.length > 0 && obj.value && obj.value.length > 0
        ? obj.choiceList.filter(choice => obj.value.includes(choice.value)).map(choice => choice.label)
        : obj.value

      return {
        showName: obj.showName,
        conditionName,
        valuesFilters: valuesFilters || 'Todos'
      }
    })

    htmlFilters += `
      <div class='flex flex-wrap w-full mt-1'>
        <span class='text-sm text-gray-200 font-bold'>Listado de Filtros aplicados:</span>
      </div>
    `

    // Mostrar los filtros aplicados
    for (let i = 0; i < result.length; i++) {
      htmlFilters += `
        <div class='flex flex-wrap items-center w-full my-2 p-2 bg-gray-600'>
          <span class='text-xs text-gray-200'>${result[i].showName}</span>
          <span class='text-xs ml-2 text-yellow-400'>${result[i].conditionName}</span>
          <span class='text-xs ml-2 text-gray-200 font-bold'>${result[i].valuesFilters}</span>
        </div>
      `
    }
  }

  return htmlFilters
}

// Funcion para mostrar el componente según la columna en la tabla
const showComponentColumn = (column) => {
  // Si column.isCurrency es true, retorna el componente ColumnTemplateCurrency
  if (column.isCurrency) {
    return ColumnTemplateCurrency
  }

  // Si la columna existe en el objeto columnComponents, retorna el componente correspondiente
  if (columnComponents[column.field]) {
    return columnComponents[column.field]
  }

  // Si la columna no se encuentra en el objeto, retorna el componente por defecto
  return ColumnTemplateDefault
}

// Watch para limpiar los filtros
watch(() => _limpiarListadoFacturasCapita.value, async (value) => {
  if (value) {
    resetClear()
    filtersFacturasCapitaStore.setLimpiarListadoFacturasCapita(false)
    filtersFacturasCapitaStore.setBuscarListadoFacturasCapita(true)
  }
})

// Watch para filtrar el listado
watch(() => _buscarListadoFacturasCapita.value, async (value) => {
  if (value) {
    offset.value = 0
    filtersFacturasCapitaStore.setBuscarListadoFacturasCapita(false)
    // Enviar los filtros a la URL pero quitando choiceList y conditions
    const filters = JSON.parse(JSON.stringify(_filtersAdvanced.value)) // Create a deep copy
    for (const key in filters) {
      if ('choiceList' in filters[key]) {
        delete filters[key].choiceList
      }
      if ('conditions' in filters[key]) {
        delete filters[key].conditions
      }
    }

    // Si se agrega un nuevo filtro, se actualiza la URL y por defecto debe tomar el limite de 20 registros y la página 1
    _routerPush({ filters: JSON.stringify(filters), limit: 20, offset: 0 })
    filtersUrl.value = JSON.parse(JSON.stringify(_filtersAdvanced.value))

    selectedColumns.value = listColumns.value
  }
})

// Watch para refrescar el listado
watch(() => _refrescarListadoFacturasCapita.value, async (value) => {
  if (value) {
    await fetchListFacturasCapitaValor()
    filtersFacturasCapitaStore.setRefrescarListadoFacturasCapita(false)
  }
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async () => {
  if (![
    'pharmasan.ventas.facturacion.capita-valor.carga-ov-factura',
    'pharmasan.ventas.facturacion.capita-valor.listado-ovs'
  ].includes(route.name)) {
    await fetchListFacturasCapitaValor()
  }
})

// Observa _filtersApply.value y actualiza filtersApplyContent cuando cambie
watch(() => _filtersApply.value, () => {
  filtersApplyContent.value = getFiltersApplyV2()
})

// Hook
onMounted(async () => {
  await fetchListFacturasCapitaValor()
  await getRouteQuery()

  selectedColumns.value = listColumns.value

  // Limpiar el store
  infoFacturasCapitaStore._actionClearInfoFacturaCapita()
})
</script>

<template>
  <div class="box intro-x h-full">
    <div class="mt-0">
      <!-- Titulo Card y botones de acción -->
      <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto capitalize text-base font-medium">Listado</h3>
        <div class="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <!-- Botón mas filtros -->
          <Button
            severity="warning"
            text
            size="small"
            style="height: 30px"
            class="mr-2"
            @click="onSidebarAdvancedFilters(null)"
          >
            <div class="flex flex-col">
              <div class="flex flex-wrap justify-center items-center">
                <Icon icon="mdi:plus" class="text-sm" />
                <span class="ml-2 text-xs font-bold">Filtros</span>
              </div>
            </div>
          </Button>
          <!-- Botón ocultar filtros principales -->
          <Button
            :severity="_filtersApply.length > 0 && _filtersApply[0].nameField ? 'success' : 'secondary'"
            text
            size="small"
            style="height: 30px"
            class="mr-2"
            v-tippy="{
              content: filtersApplyContent,
              trigger: 'click'
            }"
          >
            <div class="flex flex-col">
              <div class="flex flex-wrap justify-center items-center">
                <Icon :icon="_filtersApply.length > 0 && _filtersApply[0].nameField ? 'mdi:filter-check' : 'mdi:filter-remove'" class="text-sm" />
                <span class="ml-2 text-xs font-bold">{{ _filtersApply.length > 0 && _filtersApply[0].nameField ? 'Ver filtros' : 'Sin filtros' }}</span>
              </div>
            </div>
          </Button>
          <!-- Botones de acción -->
          <div class="flex flex-wrap h-full gap-2 mr-4">
            <Button
              icon="pi pi-trash"
              severity="danger"
              class="p-button-xs w-full"
              style="height: 30px"
              @click="resetClearFilter"
              :disabled="filtersUrl.length > 0 && !filtersUrl[0].nameField"
              v-tippy="{ content: 'Limpiar Filtros' }"
            />
            <Button
              icon="pi pi-refresh text-sm"
              severity="success"
              class="p-button-xs w-full"
              style="height: 30px"
              @click="reload()"
              v-tippy="{ content: 'Recargar' }"
            />
          </div>
          <!-- Botón exportar a excel -->
          <Button
            icon="pi pi-external-link"
            size="small"
            severity="secondary"
            label="Exportar a excel"
            @click="exportarExcel"
            style="height: 30px"
          />
        </div>
      </div>

      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4">
        <!-- Tabla -->
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <DataTable
            ref="dt"
            :value="listFacturasCapitaValor.result"
            :expandedRows="expandedRows"
            @rowExpand="onRowExpand"
            @rowCollapse="onRowCollapse"
            tableStyle="min-width: 50rem"
            class="p-datatable-sm text-xs"
            dataKey="DocEntry"
            :rowClass="rowClass"
            scrollable
            scrollHeight="700px"
            @sort="orderQuery"
            :sortField="orderField"
            :sortOrder="sortOrder"
          >
            <template #empty>
              <div class="flex flex-col items-center justify-center" style="height: 650px">
                <Icon icon="mdi:invoice-text-fast-outline" class="text-gray-200" style="font-size: 15rem;"/>
                <span class="text-gray-400 text-2xl">No se encontraron registros</span>
                <span class="text-gray-300">No hemos encontrado ninguna factura, pruebe con otro filtro u opción</span>
              </div>
            </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column
              expander
              style="width: 3rem"
              :pt="{
                rowToggler: {
                  style: 'width: 1.5rem; height: 1.5rem;'
                }
              }"
            />
            <Column
              v-for="column in selectedColumns"
              :key="column.field"
              :field="column.field"
              :header="column.header"
              :sortable="true"
            >
              <template #body="{data}">
                <component
                  :is="showComponentColumn(column)"
                  :data="data"
                  :column="column.field"
                />
              </template>
            </Column>
            <template #expansion>
              <div v-if="detailFactura.length === 0" class="w-full flex flex-col items-center justify-center">
                <Icon icon="healthicons:not-ok" class="text-5xl text-gray-500"/>
                <span class="text-red-800 opacity-70">No hay detalle</span>
              </div>
              <div class="" v-else>
                <h5 class="text-sm font-semibold text-gray-600 mb-2">Detalle de la factura</h5>
                <DataTable
                  :value="detailFactura"
                  tableStyle="min-width: 50rem"
                  class="p-datatable-sm text-xs"
                  showGridlines
                  scrollable
                  scrollHeight="500px"
                >
                  <Column
                    v-for="column in detailColumnsFactura"
                    :key="column.field"
                    :field="column.field"
                    :header="column.header"
                    :sortable="true"
                  >
                    <template #body="{data}">
                      <component
                        :is="showComponentColumn(column)"
                        :data="data"
                        :column="column.field"
                      />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
          </DataTable>
          <!-- Paginación -->
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(listFacturasCapitaValor.total)"
            :rowsPerPageOptions="[5,10,20,30,100, listFacturasCapitaValor.total]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
            :pt="{
              rowPerPageDropdown: {
                input: {
                  style: 'padding: 5px'
                },
              }
            }"
          >
            <template #start>
              <div class="flex flex-wrap items-center">
                <span class="text-xs font-bold">Total registros:</span>
                <span class="text-xs ml-2 text-green-400">{{ listFacturasCapitaValor.total }}</span>
              </div>
            </template>
            <template #end>
              <div class="flex flex-wrap items-center">
                <span class="text-xs font-bold">Registros por página:</span>
                <span class="text-xs ml-2 text-green-400">{{ limit }}</span>
              </div>
            </template>
          </Paginator>
        </div>
      </div>
    </div>
  </div>

  <SidebarAdvancedFilters ref="refSidebarAdvancedFilters" />
</template>

<style scoped>
:deep .expanded-row {
  background-color: #ffd !important;
}
</style>
