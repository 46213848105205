<script setup>
import { computed, defineProps } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  column: {
    type: String,
    default: ''
  }
})
const router = useRouter()

//
const onListadoOvs = (data) => {
  const { DocNum } = data

  router.push({
    name: 'pharmasan.ventas.facturacion.capita-valor.listado-ovs',
    params: {
      DocNum
    }
  })
}

const valueColumn = computed(() => {
  return props.data[props.column]
})

</script>

<template>
  <span v-if="valueColumn <= 0" style="padding: 0.4375rem 0.875rem">
    {{ valueColumn }}
  </span>
  <Button
    v-else
    :severity="valueColumn > 0 ? 'primary' : 'secondary'"
    text
    size="small"
    style="height: 30px"
    :class="{
      'cursor-pointer font-semibold': valueColumn > 0,
      'cursor-not-allowed font-normal': valueColumn === 0
    }"
    @click="onListadoOvs(props.data)"
    v-tippy="{
      content: valueColumn > 0 ? 'Ver Listado OVs' : 'No hay OVs',
    }"
  >
    <span>{{ valueColumn }}</span>
  </Button>
</template>

<style scoped>

</style>
