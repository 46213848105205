import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_FACTURACION_CAPITA

export default class ClientesService {
  // Listar los clientes
  getListClientes () {
    return http.get(`${baseUrl}/clientes/listar-clientes`, {
      headers: {
        loading: true
      }
    })
  }
}
