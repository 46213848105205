import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_FACTURACION_CAPITA

export default class ZonasService {
  // Listar las zonas
  getListZonas () {
    return http.get(`${baseUrl}/zonas/listar-zonas`, {
      headers: {
        loading: true
      }
    })
  }
}
