import { defineStore } from 'pinia'

import ClientesService from '@/apps/pharmasan/facturacion/capita/services/clientes.service'
import FacturasCapitaValorService from '@/apps/pharmasan/facturacion/capita/services/facturasCapitaValor.service'

const _ClientesService = new ClientesService()
const _FacturasCapitaValorService = new FacturasCapitaValorService()

export const useFiltersFacturasCapitaStore = defineStore({
  id: 'filtersFacturasCapita',
  state: () => ({
    _filtersApply: [],
    _buscarListadoFacturasCapita: false,
    _refrescarListadoFacturasCapita: false,
    _limpiarListadoFacturasCapita: false,
    _showHideFilters: true,
    _filtersAdvanced: [{
      nameField: null,
      condition: null,
      showName: null,
      dataType: null,
      value: []
    }],
    _listClientes: [],
    _listMotivosAutorizacion: [],
    _listEstadosCartera: [],
    _listFiltersFields: [
      { nameField: 'DocNum', showName: 'DocNum', choiceList: [], dataType: 'text' },
      { nameField: 'DocDate', showName: 'FechaFactura', choiceList: [], dataType: 'date' },
      { nameField: 'CardCode', showName: 'Cliente', choiceList: [], dataType: 'select' },
      { nameField: 'Estado', showName: 'Estado', choiceList: [{ value: 'O', label: 'ABIERTO' }, { value: 'C', label: 'CERRADO' }], dataType: 'select' },
      { nameField: 'DiscPrcnt', showName: '%Descuento', choiceList: [], dataType: 'number' },
      { nameField: 'DocTotal', showName: 'VlrTotal', choiceList: [], dataType: 'number' },
      { nameField: 'U_PHR_EstadoFac', showName: 'EstadoFacturaCartera', choiceList: [], dataType: 'select' },
      { nameField: 'CantidadOvs', showName: 'Cant.OVs', choiceList: [], dataType: 'number' }
    ],
    _listFiltersConditions: [
      {
        dataType: 'text',
        conditions: [
          { value: 'contains', name: 'Contiene' },
          { value: 'notContains', name: 'No contiene' },
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'in', name: 'estan en la lista' },
          { value: 'notIn', name: 'no estan en la lista' }
        ]
      },
      {
        dataType: 'number',
        conditions: [
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'greaterThan', name: 'Mayor que' },
          { value: 'lessThan', name: 'Menor que' }
        ]
      },
      {
        dataType: 'date',
        conditions: [
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'greaterThan', name: 'Mayor que' },
          { value: 'lessThan', name: 'Menor que' },
          { value: 'greaterOrEquals', name: 'Mayor o igual que' },
          { value: 'lessOrEquals', name: 'Menor o igual que' }
        ]
      },
      {
        dataType: 'select',
        conditions: [
          { value: 'equals', name: 'Igual a' },
          { value: 'notEquals', name: 'Diferente de' },
          { value: 'in', name: 'estan en la lista' },
          { value: 'notIn', name: 'no estan en la lista' }
        ]
      }
    ]
  }),
  getters: {
    getFiltersApply: (state) => state._filtersApply,
    getBuscarListadoFacturasCapita: (state) => state._buscarListadoFacturasCapita,
    getRefrescarListadoFacturasCapita: (state) => state._refrescarListadoFacturasCapita,
    getLimpiarListadoFacturasCapita: (state) => state._limpiarListadoFacturasCapita,
    getListFiltersFields: (state) => state._listFiltersFields,
    getFiltersAdvanced: (state) => state._filtersAdvanced,
    getListClientes: (state) => state._listClientes,
    getListMotivosAutorizacion: (state) => state._listMotivosAutorizacion,
    getListEstadosCartera: (state) => state._listEstadosCartera
  },
  actions: {
    setBuscarListadoFacturasCapita (payload) {
      this._buscarListadoFacturasCapita = payload
    },
    setRefrescarListadoFacturasCapita (payload) {
      this._refrescarListadoFacturasCapita = payload
    },
    setLimpiarListadoFacturasCapita (payload) {
      this._limpiarListadoFacturasCapita = payload
    },
    setFiltersApply (payload) {
      this._filtersApply = payload
    },
    setFiltersAdvanced (payload) {
      this._filtersAdvanced = payload
    },
    setListFiltersFields (payload) {
      this._listFiltersFields = payload
    },
    setListClientes (payload) {
      this._listClientes = payload
    },
    setListMotivosAutorizacion (payload) {
      this._listMotivosAutorizacion = payload
    },
    setListEstadosCartera (payload) {
      this._listEstadosCartera = payload
    },
    actionClearFilters () {
      this._filtersAdvanced = [{
        nameField: null,
        condition: null,
        showName: null,
        dataType: null,
        value: []
      }]
      this._filtersApply = []
    },
    async _actionGetListClientes () {
      try {
        const { data } = await _ClientesService.getListClientes()
        this.setListClientes(data.result)
      } catch (error) {
        console.log('Error en _actionFilterClientes', error)
      }
    },
    async _actionGetListMotivosAutorizacion () {
      try {
        const { data } = await _FacturasCapitaValorService.getListMotivoAutorizacion()
        this.setListMotivosAutorizacion(data.result)
      } catch (error) {
        console.log('Error en _actionGetListMotivosAutorizacion', error)
      }
    },
    async _actionGetListEstadosCartera () {
      try {
        const { data } = await _FacturasCapitaValorService.getListEstadosCartera()
        this.setListEstadosCartera(data.result)
      } catch (error) {
        console.log('Error en _actionGetListEstadosCartera', error)
      }
    }
  }
})
